//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

// turn into hours and minutes
import Wishlist from '../actions/Wishlist.vue'
import IconBase from '../IconBase.vue'
import Authors from '../others/Authors.vue'
export default {
  components: { Authors, Wishlist, IconBase },
  props: {
    info: {
      type: Object,
      default: () => {},
    },
    // standard, premium, pin
    type: {
      type: [String, Number],
      default: 'standart',
    },
    isEvent: {
      type: Boolean,
      default: false,
    },
    // all pin premium
    eventStatus: {
      type: String,
      default: '',
    },
    // both, offline, online
    // event or vacancy's slug
    url: {
      type: String,
      required: true,
    },
    // will show stats icon
    isMine: {
      type: Boolean,
      default: false,
    },
    // will show message about vacancy premium status
    status: {
      type: Number,
      default: 0,
    },
    // CATEGORY, COMPANY, AUTHORS
    wishlist: {
      type: Object,
      default: () => ({}),
    },
    typeSaved: {
      type: String,
      default: '',
    },
  },
  methods: {
    handleEventTime(date) {
      const now = new Date()
      const pareseDate = new Date(date)
      const { hours, minutes } = this.$getHoursAndMinutes(date)
      if (hours < 24 && hours > 1) {
        return 'event--yellow'
      } else if (hours < 1 && minutes > 1) {
        return 'event--red'
      } else if (
        (now - pareseDate) / (1000 * 60 * 60) < 1 &&
        (now - pareseDate) / (1000 * 60 * 60) > 0
      ) {
        return 'event--green'
      } else if ((now - pareseDate) / (1000 * 60 * 60) > 1) {
        return '!hidden'
      }
    },
  },
}

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapState } from 'vuex'
import BeforeAuth from './BeforeAuthLinks.vue'
import IconBase from '@/components/IconBase.vue'
export default {
  components: {
    IconBase,
    BeforeAuth,
  },
  props: {
    noAutoHandle: {
      type: [Boolean, String],
      default: false,
    },
    list: {
      type: Array,
      default: () => [
        {
          title: 'to_complain',
          svg: 'three-dots-report',
          titleStyle: '',
          class: 'flex items-center font-semibold text-black font-medium',
        },
        {
          title: 'block',
          svg: 'three-dots-block',
          titleStyle: '',
          class: 'flex items-center font-semibold text-black font-medium',
        },
      ],
    },
    authorID: {
      type: [String, Number],
      default: '',
    },

    blocked: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      showingLinks: false,
      list1: [
        // {
        //   title: 'report',
        //   // svg: 'event-single-complain',
        //   class: 'flex items-center font-semibold text-black font-medium'
        // },
        {
          title: 'unblock',
          class: 'flex items-center font-semibold text-black font-medium',
        },
      ],
    }
  },
  computed: {
    ...mapState({
      user: (state) => state.auth.user,
    }),
  },
  methods: {
    emitIndex(i, hide) {
      if (hide) {
        this.$emit('event1')
      } else {
        this.$emit(`event${i}`)
        if (this.noAutoHandle) {
          this.$emit(`event${i}`)
        } else if (i === 0 && this.$auth.loggedIn) {
          this.$modal('deleteTypeReport')
          this.$store.commit('reports/SET_AUTHOR_ID', this.authorID)
        } else if (!this.showingLinks) {
          this.showingLinks = true
        } else {
          this.showingLinks = false
        }
      }
    },
  },
}

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapState } from 'vuex'
import LazyHydrate from 'vue-lazy-hydration'
import HashTagHeader from '../components/actions/HashTagHeader.vue'
import Post from '../components/lenta/Post.vue'
import Vacancy from '../components/lenta/Vacancy.vue'
import EventVacancy from '../components/lenta/EventVacancy.vue'
import DiscussionArticles from '../components/lenta/DiscussionArticles'
import TopComments from '../components/lenta/TopComments.vue'
import News from '../components/lenta/News.vue'
import seo from '../assets/scripts/module'
import PreloaderNotFound from '../components/others/preloader/PreloaderNotFound.vue'
import LoaderList from '@/components/actions/LoaderList.vue'
export default {
  name: 'Home',
  components: {
    Post,
    HashTagHeader,
    Vacancy,
    EventVacancy,
    DiscussionArticles,
    TopComments,
    LoaderList,
    News,
    PreloaderNotFound,
    LazyHydrate,
  },
  data() {
    return {
      name: '',
      page: 1,
      home: [],
      filterDate: '',
      selectedFitlerId: '',
      datesById: {
        2: this.getDate(1),
        3: this.getDate(7),
        4: this.getDate(30),
        5: this.getDate(365),
      },
    }
  },
  async fetch() {
    await this.$store.dispatch('getWSArtcile', 5).catch(() => {})
    await this.fetchData()
  },

  head() {
    return {
      ...seo({
        title: this.meta.title,
        desc: this.meta.desc,
        img: this.meta.img,
        url: 'https://lenta.uz/',
      }),
    }
  },
  computed: {
    ...mapState({
      hasNext: (state) => state.home.hasNext,
      meta: (state) => state.home.meta.index,
    }),
  },
  async created() {
    console.log(this.home)
    if (!this.$auth.loggedIn) {
      await this.$store.dispatch('getWSArtcile', 5).catch(() => {})
      await this.fetchData()
    }
    this.$store.commit('setChatbarHidden', 0)
    this.$store.commit('setChatbarToggle', 1100)
  },
  methods: {
    handleFilter(id) {
      this.filterDate = this.turnIdIntoDate(id)
      this.home = []
      this.page = 1
      this.$fetch()
    },
    getDate(day) {
      const date = new Date()
      // get current date yyyy-mm-dd and minus the day
      date.setDate(date.getDate() - day)
      return date.toISOString().split('T')[0]
    },
    turnIdIntoDate(id) {
      this.selectedFitlerId = id
      if (this.datesById[id]) {
        return this.datesById[id]
      } else {
        return ''
      }
    },
    handleShowMore() {
      this.$router.push('/' + this.$i18n.locale + '/events')
    },
    loadMore(entries, observer, isIntersecting) {
      if (isIntersecting) {
        this.page++
        this.fetchData()
      }
    },
    async fetchData() {
      await this.$store
        .dispatch('home/getHome', {
          page: this.page,
          order: '-published_date',
          date: this.filterDate,
        })
        .then((res) => {
          this.home.push(...res.data.results)
        })
        .catch(() => {})
    },
  },
}

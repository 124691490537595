//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import DiscussionArticlesInner from './DiscussionArticlesInner'

export default {
  components: { DiscussionArticlesInner },
  props: {
    title: {
      type: String,
      default: '',
    },
    url: {
      type: String,
      default: '',
    },
    list: {
      type: Array,
      default: () => [],
    },
  },
}

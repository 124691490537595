//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapState } from 'vuex'
export default {
  props: {
    post: {
      type: Boolean,
      default: false,
    },
    isSubscribed: {
      type: Boolean,
      default: false,
    },
    id: {
      type: [String, Number],
      default: 1,
    },
    rating: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      test: false,
      subed: !!this.isSubscribed,
      isVisible: !this.isSubscribed,
      timeout: false,
      timeoutOne: null,
    }
  },
  computed: {
    ...mapState({
      user: (state) => state.auth.user,
    }),
  },
  methods: {
    async subscribeToPost() {
      if (!this.user) return this.$router.push('/auth?message=login_first')

      if (this.subed) {
        // unsubscribe
        await this.$axios
          .post('/author/subscription/unsubscribe/', {
            author: this.id,
          })
          .then(() => {
            this.$toast.success(this.$t('subscribeComp.unfollow'))
            this.timeout = false
            clearTimeout(this.timeoutOne)
            const vm = this
            setTimeout(() => {
              vm.subed = false
            }, 700)
            // after 5 seconds button will be hidden
          })
          .catch(() => {
            this.$toast.error(this.$t('subscribeComp.error'))
          })
      } else {
        // subscribe
        await this.$axios
          .post('/author/subscription/subscribe/', {
            author: this.id,
          })
          .then(() => {
            this.$toast.success(this.$t('subscribeComp.follow'))
            this.subed = true
            this.timeout = true
            const vm = this
            this.timeoutOne = setTimeout(() => {
              vm.timeout = false
            }, 5000)
          })
          .catch(() => {
            this.$toast.error(this.$t('subscribeComp.error'))
          })
      }
    },
  },
}

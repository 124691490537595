//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapState } from 'vuex'
import BeforeAuth from './BeforeAuthLinks.vue'
export default {
  components: { BeforeAuth },
  props: {
    info: {
      type: Object,
      default: () => {},
    },
    count: {
      type: Number,
      default: 0,
    },
    id: {
      type: Number,
      default: 0,
    },
    isLiked: {
      type: Boolean,
    },
    isDisliked: {
      type: Boolean,
    },
    hoverable: {
      type: Boolean,
    },
    likeAPI: {
      type: String,
      default: 'article/likes/like/',
    },
    dislikeAPI: {
      type: String,
      default: 'article/likes/dislike/',
    },
    isComment: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      dialog: false,
      showingLinks: false,
      localCount: this.info.score,
      num: this.info.score,
      liked: this.isLiked,
      disliked: this.isDisliked,
      changed: false,
    }
  },
  computed: {
    ...mapState({
      user: (state) => state.auth.user,
    }),
  },
  mounted() {
    if (this.isLiked) {
      this.num = this.info.score
      this.localCount = this.info.score - 1
    } else if (this.isDisliked) {
      this.num = this.info.score
      this.localCount = this.info.score + 1
    }
  },
  methods: {
    downvote() {
      //   if (!this.user) return this.$router.push('/auth?message=login_first');
      if (!this.user && !this.showingLinks) {
        this.showingLinks = true
      } else {
        this.showingLinks = false
      }

      if (this.num !== this.localCount - 1) {
        this.num -= 1
        this.changed = true
        this.downVote()
      }
    },
    upvote() {
      //   if (!this.user) return this.$router.push('/auth?message=login_first');
      if (!this.user && !this.showingLinks) {
        this.showingLinks = true
      } else {
        this.showingLinks = false
      }

      if (this.num !== this.localCount + 1) {
        this.num += 1
        this.changed = true
        this.upVote()
      }
    },
    async downVote() {
      const data = this.commentOrArticle()
      await this.$axios.post(this.dislikeAPI, data)
    },
    async upVote() {
      const data = this.commentOrArticle()
      await this.$axios.post(this.likeAPI, data)
    },
    removeMinus(num) {
      return num.toString().replace(/^-/, '')
    },
    commentOrArticle() {
      if (this.isComment) {
        return {
          comment: this.info.id,
        }
      } else {
        return {
          article: this.info.id,
        }
      }
    },
  },
}

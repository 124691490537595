//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import LottieAnimation from 'lottie-web-vue' // import lottie-web-vue
import Preloader from '../../../components/others/preloader/Preloader.vue'
export default {
  name: 'PreloaderNotFound',
  components: {
    Preloader,
    LottieAnimation,
  },
  props: {
    isLoading: { type: Boolean, default: false },
    classC: { type: String, default: '' },
    fetchState: {
      type: Object,
      default: () => ({}),
    },
    list: {
      type: Array,
      default: () => [],
    },
    text: {
      type: String,
      default: '',
    },
    svgClass: {
      type: String,
      default: '',
    },
    actualList: {
      type: Array,
      default: () => [],
    },
  },
}

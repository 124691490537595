//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  props: {
    selectedDefault: {
      type: [Number, String],
      default: 0,
    },
  },
  data() {
    return {
      active: this.$route.path.includes('/popular') ? 1 : 2,
      // don't change these objects id
      items: [
        {
          text: this.$t('popular'),
          id: 1,
        },
        { text: this.$t('during_the_day'), id: 2 },
        { text: this.$t('during_the_week'), id: 3 },
        { text: this.$t('during_the_month'), id: 4 },
        { text: this.$t('during_the_year'), id: 5 },
        { text: this.$t('during_all_times'), id: 6 },
      ],
      hashtags: [],
      activeTags: [],
      selected: this.selectedDefault || 1,
    }
  },
  watch: {
    selected(newValue, oldValue) {
      this.$emit('change', newValue)
    },
  },
}

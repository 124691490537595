var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"relative !bg-transparent"},[_c('client-only',{scopedSlots:_vm._u([{key:"placeholder",fn:function(){return [_c('div',{staticClass:"!max-w-[736px] mx-auto my-6 flex flex-col gap-5 f:gap-4"},[_c('PreloaderNot-found',{attrs:{"actual-list":_vm.home,"list":['news', 'post', 'event', 'post', 'discussion'],"fetch-state":_vm.$fetchState,"is-loading":true}})],1)]},proxy:true}])},[_vm._v(" "),_c('div',{staticClass:"container"},[_c('div',{staticClass:"!max-w-[736px] mx-auto my-6 flex flex-col gap-5 f:gap-4"},[(_vm.home)?_c('div',[_c('PreloaderNotFound',{attrs:{"actual-list":_vm.home,"list":['news', 'post', 'event', 'post', 'discussion'],"fetch-state":_vm.$fetchState}})],1):_vm._e(),_vm._v(" "),(_vm.home && _vm.home.length)?[_c('News',_vm._b({},'News',{ date: new Date(), moreUrl: '/hell' },false)),_vm._v(" "),_vm._l((_vm.home),function(item,index){return [(item.object_type === 'article')?_c('div',{key:index},[(index === 0)?_c('HashTagHeader',{attrs:{"selected-default":_vm.selectedFitlerId},on:{"change":_vm.handleFilter}}):_vm._e(),_vm._v(" "),_c('LazyHydrate',{attrs:{"when-visible":""}},[_c('Post',_vm._b({attrs:{"has-header":index === 0,"info":item.article},on:{"refetch":function($event){_vm.posts = []
                    _vm.$fetch()}}},'Post',{
                    wishlist: {
                      type: 'article',
                    },
                    vote: {
                      isDisliked: item.article.reaction.is_disliked,
                      isLiked: item.article.reaction.is_liked,
                    },
                    comments: {
                      newImg: 'asfasfasf',
                    },
                    isPost: true,
                    sameAsCategory: item.article.is_category_author,
                  },false))],1)],1):(item.object_type === 'vacancy')?_c('div',{key:index,staticClass:"overflow-hidden"},[_c('Vacancy',_vm._b({attrs:{"info":item.vacancy,"url":'/vacancy/' + _vm.info.slug}},'Vacancy',{
                  wishlist: {
                    type: 'vacancy',
                  },
                  type: item.vacancy.status,
                },false))],1):(item.object_type === 'event')?_c('div',{key:index,staticClass:"overflow-hidden"},[_c('Vacancy',_vm._b({attrs:{"info":item,"url":'/events/' + item.event.slug,",":""},on:{"stats":function($event){return _vm.$modal('activiteModal')}}},'Vacancy',{
                  isEvent: true,
                  wishlist: {
                    type: 'event',
                  },
                  type: item.event.status,
                },false))],1):(item.object_type === 'event_bundle')?_c('div',{key:index},[_c('EventVacancy',_vm._b({attrs:{"info":item}},'EventVacancy',{
                  home: true,
                  isEvent: true,
                  page: 1,
                  total: 6,
                  title: _vm.$t('events'),
                  addButtonText: _vm.$t('add_events'),
                  addUrl: '/events/add',
                  indexHeader: true,
                },false))],1):(item.object_type === 'vacancy_bundle')?_c('div',{key:index},[_c('EventVacancy',_vm._b({attrs:{"info":item}},'EventVacancy',{
                  home: true,
                  isEvent: false,
                  page: 1,
                  total: 6,
                  title: _vm.$t('vacancies'),
                  addButtonText: _vm.$t('add_vacancies'),
                  addUrl: '/vacancy/add',
                  indexHeader: true,
                },false))],1):(item.object_type === 'discussion_article_bundle')?_c('div',{key:index},[_c('DiscussionArticles',_vm._b({},'DiscussionArticles',{
                  title: 'Muhokamadagi maqolalar',
                  list: item.items,
                },false))],1):_vm._e()]}),_vm._v(" "),(_vm.hasNext)?_c('LoaderList',{directives:[{name:"intersect",rawName:"v-intersect",value:(_vm.loadMore),expression:"loadMore"}],staticClass:"mb-[10px]"}):_vm._e(),_vm._v(" "),(false)?_c('TopComments',_vm._b({},'TopComments',{
              title: 'Eng zo’r izohlar',
            },false)):_vm._e()]:_vm._e()],2)])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }
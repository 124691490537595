//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  props: {
    info: {
      type: Object,
      default: () => {},
    },
    // latest comments avatar animation
    newImg: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      animate: false,
    }
  },
  mounted() {
    this.animate = true
    setInterval(() => {}, 1000)
  },
}

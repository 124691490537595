var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"vote relative",class:{ _hoverable: _vm.hoverable }},[_c('div',{staticClass:"vote__down-vote",class:[{ active: _vm.num < _vm.localCount }],on:{"click":function($event){$event.preventDefault();return _vm.downvote.apply(null, arguments)}}},[_c('svg',{attrs:{"width":"24","height":"24","viewBox":"0 0 24 24","fill":"none","xmlns":"http://www.w3.org/2000/svg"}},[_c('path',{attrs:{"d":"M6 9L12 15L18 9","stroke":"#8A939A","stroke-width":"2","stroke-linecap":"round","stroke-linejoin":"round"}})])]),_vm._v(" "),_c('div',{staticClass:"vote__count",class:[
      { hoverable: _vm.hoverable },
      { up: _vm.num > _vm.localCount },
      { down: _vm.num < _vm.localCount },
      { '!text-[#4CAF50]': _vm.num > 0 },
      { '!text-[#FF4040]': _vm.num < 0 },
      { 'green-light': _vm.num > 0 && _vm.hoverable },
      { 'red-light': _vm.num < 0 && _vm.hoverable },
      { 'gray-light': _vm.num === 0 && _vm.hoverable } ]},[_c('span',{staticClass:"vote__count-n"},[_vm._v(_vm._s(_vm.removeMinus(_vm.localCount + 1)))]),_vm._v(" "),_c('span',{staticClass:"vote__count-n"},[_vm._v(_vm._s(_vm.removeMinus(_vm.localCount)))]),_vm._v(" "),_c('span',{staticClass:"vote__count-n"},[_vm._v(" "+_vm._s(_vm.removeMinus(_vm.localCount - 1)))])]),_vm._v(" "),_c('div',{staticClass:"vote__up-vote",class:[{ active: _vm.num > _vm.localCount }],on:{"click":function($event){$event.preventDefault();return _vm.upvote.apply(null, arguments)}}},[_c('svg',{attrs:{"width":"24","height":"24","viewBox":"0 0 24 24","fill":"none","xmlns":"http://www.w3.org/2000/svg"}},[_c('path',{attrs:{"d":"M18 15L12 9L6 15","stroke":"#8A939A","stroke-width":"2","stroke-linecap":"round","stroke-linejoin":"round"}})])]),_vm._v(" "),_c('BeforeAuth',{attrs:{"showing-links":_vm.showingLinks},on:{"update:showingLinks":function($event){_vm.showingLinks=$event},"update:showing-links":function($event){_vm.showingLinks=$event},"falsifyShowingLinks":function($event){_vm.showingLinks = false}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapState } from 'vuex'
// import IconBase from '../IconBase.vue'
// import Input from '../form/Input.vue'
import ConverterList from '../actions/ConverterList.vue'
export default {
  components: { ConverterList },
  props: {
    date: {
      type: [String, Date],
      default: '',
    },
    moreUrl: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      symbol: Symbol('key'),
      itemsToShow: 5,
      pending: false,
      posts: [],
      trashPosts: [],
      groupedList: [],
      count: 10,
      limit: 5,
    }
  },
  computed: {
    ...mapState({
      // the function is called in index.vue page
      articles: (state) => state.articles,
      articlesCount: (state) => state.articlesCount,
    }),
  },
  created() {
    if (process.client) {
      try {
        this.connection = new WebSocket(this.$config.WS_URL)
        // handle error cases
        this.connection.onerror = () => {}
        this.connection.onmessage = function (event) {
          const data = JSON.parse(event.data)
          if (data.type === 'news') {
            if (data.message.method === 'created') {
              this.trashPosts.push(this.posts.pop())
              this.posts.unshift(data.message)
            } else if (data.message.method === 'delete') {
              this.posts = this.posts.filter(
                (comment) => comment.id !== data.message.id
              )
              this.posts.push(this.trashPosts.pop())
            } else if (data.message.method === 'update') {
              this.posts = this.posts.map((comment) => {
                if (comment.id === data.message.id) {
                  return data.message
                }
                return comment
              })
            }
            this.groupedList = this.groupByDate()
          }
        }.bind(this)
        this.connection.onopen = function (event) {}
      } catch {}
      this.posts = [...this.articles]
    }
    this.groupedList = this.groupByDate(this.posts)
  },
  methods: {
    async handleShowMore() {
      if (this.pending) {
        return true
      }
      this.pending = true
      this.limit += 5
      await this.fetchPosts()
    },
    async fetchPosts() {
      await this.$store.dispatch('getWSArtcile', this.limit).then((res) => {
        this.pending = false
        this.count = res.data.count
        this.posts = res.data.results
        this.groupedList = this.groupByDate(this.posts)
      })
    },
    groupByDate() {
      const groups = this.posts.reduce((groups, data) => {
        const date = data.published_date.split(' ')[0]
        if (!groups[date]) {
          groups[date] = []
        }
        groups[date].push(data)
        return groups
      }, {})
      // Edit: to add it in the array format instead
      const groupArrays = Object.keys(groups).map((date) => {
        return {
          date,
          posts: groups[date],
        }
      })
      return groupArrays
    },
  },
}

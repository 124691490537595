//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapState } from 'vuex'
import { required } from 'vuelidate/lib/validators'
import BeforeAuth from './BeforeAuthLinks.vue'
import Modal from '@/components/form/Modal.vue'
import Input from '@/components/form/Input.vue'

export default {
  components: {
    Input,
    Modal,
    BeforeAuth,
  },
  props: {
    info: {
      type: Object,
      default: () => {},
    },
    type: {
      // without this api will not work
      type: String, // article, event, vacancy
      default: '',
    },
  },
  data() {
    return {
      active: false,
      name: '',
      showingLinks: false,
      trigger: 1,
    }
  },
  computed: {
    ...mapState({
      list: (state) => state.listWishlist,
      user: (state) => state.auth.user,
    }),
  },
  watch: {
    'info.is_saved': {
      handler(newValue) {
        this.active = newValue
      },
      immediate: true,
    },
  },
  validations: {
    name: {
      required,
    },
  },
  methods: {
    showWishlist($el) {
      if (!this.user || this.active) return
      $el.querySelector('.wishlizt-type').classList.add('active')
    },
    hideWishlist($el) {
      if (!this.user || this.active) return
      $el.querySelector('.wishlizt-type').classList.remove('active')
    },
    async addWishlist() {
      this.$v.name.$touch()
      if (!this.$v.name.$anyError) {
        await this.$store
          .dispatch('addWishlist', { data: { title: this.name } })
          .then(() => {
            this.$modalHide('addWishlist')
            this.name = ''
            this.$v.name.$reset()
            this.$store.dispatch('wishlist').then((response) => {
              this.$store.commit('setWishlist', response)
            })
          })
      }
    },
    async wish(wishlistId) {
      if (!this.user && !this.showingLinks) {
        this.showingLinks = true
        return
      } else if (!this.user) {
        this.showingLinks = false
        return
      }
      // eslint-disable-next-line no-unreachable
      if (this.active && this.type) {
        await this.$store
          .dispatch(`${this.type}WishlistDelete`, {
            data: { [`${this.type}`]: this.info.id },
          })
          .then(() => {
            this.active = false
            this.$emit('isWishlist', this.active)
            this.$toast.success(this.$t('wishlist.remove'))
            this.$store.dispatch('wishlist').then((response) => {
              this.$store.commit('setWishlist', response)
            })
          })
          .catch(() => {})
        // eslint-disable-next-line no-unreachable
      } else if (this.type) {
        await this.$store
          .dispatch(`${this.type}WishlistCreate`, {
            data: {
              saved:
                wishlistId ||
                this.list?.find((item) => item.default === true)?.id,
              [`${this.type}`]: this.info.id,
            },
          })
          .then(() => {
            this.active = true
            this.$emit('isWishlist', this.active)
            this.$toast.success(this.$t('wishlist.add'))
            this.$store.dispatch('wishlist').then((response) => {
              this.$store.commit('setWishlist', response)
            })
          })
          .catch(() => {})
      }
    },
  },
}

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import ThreeDots from '../actions/ThreeDots.vue'
import Vote from '../actions/Vote.vue'
import Wishlist from '../actions/Wishlist.vue'
import Authors from '../others/Authors.vue'
import Comments from '../others/CommentsIcon.vue'
import Subscirbe from '../actions/Subscribe.vue'
import ReportCard from '../../components/ReportCard.vue'
import Modal from '@/components/form/Modal.vue'

export default {
  components: {
    Authors,
    Modal,
    Comments,
    Wishlist,
    Vote,
    ThreeDots,
    Subscirbe,
    ReportCard,
  },
  props: {
    info: {
      type: Object,
      default: () => {},
    },
    isEvent: {
      type: Boolean,
      default: false,
    },
    isSubscribed: {
      type: Boolean,
      default: false,
    },
    media: {
      type: Boolean,
      default: true,
    },
    hasHeader: {
      type: Boolean,
      default: false,
    },
    draft: {
      type: Boolean,
    },
    // INNER COMPONENTS' PROPS
    comments: {
      type: Object,
      default: () => {},
    },
    wishlist: {
      type: Object,
      default: () => {},
    },
    vote: {
      type: Object,
      default: () => {},
    },
    isPost: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      heightNess: '',
      dotsList: [],
    }
  },
  // computed: {
  //   metaClass() {
  //     const sizes = this.info.image_url?.split('__')[1]
  //     if (!sizes) return false
  //     if (sizes === 'lhEyFmE') return false
  //     const widthStr = sizes.split('x')[0]
  //     const heightStr = sizes.split('x')[1].split('.')[0]
  //     const width = parseInt(widthStr)
  //     const height = parseInt(heightStr)
  //     if (width >= height && width >= 736) {
  //       return 'very-full'
  //     } else {
  //       return 'not much'
  //     }
  //   },
  // },
  mounted() {
    this.dotsList = this.info.author.is_active
      ? [
          {
            title: 'edit_post',
            svg: 'three-dots-edit',
          },
          {
            title: 'delete_post',
            svg: 'three-dots-delete',
          },
        ]
      : [{ title: 'report', svg: 'three-dots-report' }]
  },
  methods: {
    getMeta(url) {
      const img = new Image()
      img.src = url
      const that = this
      img.onload = function () {
        if (this.width >= this.height && this.width >= 736) {
          that.heightNess = 'very-full'
        }
      }
    },
    hidePost() {
      this.$modal('logOut' + this.info.id)
    },
    async deletePost() {
      await this.$axios
        .delete(`/article/crud/${this.info.id}/`)
        .then(() => {
          this.$modalHide('logOut' + this.info.id)
          this.$emit('refetch')
        })
        .catch(() => {})
    },
    async report() {
      if (this.info.author.is_request_user) {
        this.$router.push('/post/edit/' + this.info.slug)
      } else {
        await this.$store.dispatch('reports/fetchReports').catch(() => {})
      }
    },
    embedYouTubeURL(url) {
      // this function is used to embed youtube video in iframe
      // eslint-disable-next-line no-useless-escape
      return url.replace(
        /^(?:https?:\/\/)?(?:www\.)?(?:youtu\.be\/|youtube\.com\/(?:embed\/|v\/|watch\?v=|watch\?.+&v=))((\w|-){11})(?:\S+)?$/,
        'https://www.youtube.com/embed/$1'
      )
    },
  },
}

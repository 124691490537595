//
//
//
//
//
//
//
//

import Skeleton from './Skeleton.vue'
export default {
  components: { Skeleton },
  props: {
    list: {
      type: Array,
      default: () => [
        'news',
        'post',
        'event',
        'post',
        'discussion',
        'post',
        'event',
        'post',
      ],
    },
  },
}

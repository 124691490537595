//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import TopCommentsInner from './TopCommentsInner.vue'
export default {
  components: { TopCommentsInner },
  props: {
    title: {
      type: String,
      default: '',
    },
    list: {
      type: Array,
      default: () => [],
    },
  },
}

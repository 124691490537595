var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"hashtag shaddow !rounded-b-none py-[12px] px-[20px] gap-[32px] e:px-[16px]"},[_c('div',{staticClass:"flex items-center justify-between hashtag__tab gap-[2px]"},[_c('nuxt-link',{staticClass:"hashtag__latest",class:{ active: _vm.active === 2 },attrs:{"to":_vm.localePath('/')},nativeOn:{"click":function($event){_vm.active = 2}}},[_vm._v("\n      "+_vm._s(_vm.$t('latter'))+"\n    ")]),_vm._v(" "),(_vm.active === 1)?_c('v-select',{staticClass:"hashtag__select !w-[100px] !pt-0 !mt-0 rounded-[6px]",class:{ active: _vm.active === 1 },attrs:{"hide-details":true,"menu-props":{
        bottom: true,
        offsetY: true,
        nudgeLeft: 0,
        nudgeBottom: 11,
        closeOnClick: true,
        contentClass: 'hashtag__options',
      },"items":_vm.items,"item-text":"text","item-value":"id"},model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}}):_c('nuxt-link',{staticClass:"hashtag__latest",attrs:{"to":_vm.localePath('/popular')},nativeOn:{"click":function($event){_vm.active = 1}}},[_vm._v("\n      "+_vm._s(_vm.$t('popular'))+"\n    ")])],1),_vm._v(" "),_c('div',{staticClass:"hashtag__tags flex items-center gap-[14px] overflow-hidden a:!hidden"},[_vm._l((_vm.hashtags),function(item,index){return [_c('div',{key:index,staticClass:"hashtag__tag",class:{ active: _vm.activeTags.includes(item.id) }},[_c('span',[_vm._v(_vm._s(item.title))]),_vm._v(" "),_c('svg',{attrs:{"width":"12","height":"12","viewBox":"0 0 12 12","fill":"none","xmlns":"http://www.w3.org/2000/svg"}},[_c('path',{attrs:{"d":"M10 2L2 10","stroke":"white","stroke-width":"1.2","stroke-linecap":"round","stroke-linejoin":"round"}}),_vm._v(" "),_c('path',{attrs:{"d":"M2 2L10 10","stroke":"white","stroke-width":"1.2","stroke-linecap":"round","stroke-linejoin":"round"}})])])]})],2)])}
var staticRenderFns = []

export { render, staticRenderFns }
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  props: {
    title: {
      type: String,
      default: '',
    },
    company: {
      type: String,
      default: '',
    },
    companyUrl: {
      type: String,
      default: '',
    },
    url: {
      type: String,
      default: '',
    },
    // !EVENT PROPS
    isEvent: {
      type: Boolean,
    },
    price: {
      type: Number,
      default: 0,
    },
    isFree: {
      type: Boolean,
    },
    // !VACANCY PROPS
    isFixed: {
      type: Boolean,
    },
    from: {
      type: [Number, String],
      default: 0,
    },
    to: {
      type: [Number, String],
      default: 0,
    },
    currency: {
      type: String,
      default: 'uzs',
    },
  },
  methods: {
    redirectToSingle(e) {
      if (e.target.tagName === 'A') return
      this.$router.push(this.localePath(this.url))
    },
  },
}
